import request from '../utils/request';
import qs from "qs";

export const list = param => {
	return request.post('/api/serial/list', qs.stringify(param))
};

export const userSerial = param => {
	return request.post('/api/serial/userSerial', qs.stringify(param))
};

export const cancel = id => {
	return request.get('/api/serial/cancel?id=' + id)
};


export const querySubAgent = param => {
	return request.post('/api/agent/querySubAgent', qs.stringify(param))
};

export const deleteSubAgent = param => {
	return request.post('/api/agent/deleteSubAgent', qs.stringify(param))
};

export const addSubAgent = param => {
	return request.post('/api/agent/addSubAgent', qs.stringify(param))
};
export const updateSubAgent = param => {
	return request.post('/api/agent/updateSubAgent', qs.stringify(param))
};
