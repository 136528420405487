import request from '../utils/request';
import qs from "qs";
//蜗牛充值支付
export const woniuPay = param => {
	return request.post('/api/topUp/pay', param, {
		headers: {
			'Content-Type': 'application/json'
		}
	})
};

//导出
export const download = param => {
	return request.post('/api/topUp/download', qs.stringify(param), {
		responseType: 'blob'
	})
};
//迪迦用户信息查询
export const dxtQuery = param => {
	return request.post('/api/dxt/query', qs.stringify(param))
};
//迪迦用户信息查询
export const dxtbatchQuery = param => {
	return request.post('/api/dxt/batchQuery', qs.stringify(param))
};
//迪迦用户信息批量查询
export const batchQueryRecord = param => {
	return request.post('/api/dxt/batchQueryRecord', qs.stringify(param))
};
//迪迦停复机记录
export const stopOrOpenRecord = param => {
	return request.post('/api/dxt/stopOrOpenRecord', qs.stringify(param))
};

//迪迦添加停复机
export const stopOrOpen = param => {
	return request.post('/api/dxt/stopOrOpen', qs.stringify(param))
};


//迪迦限制激活记录
export const activateRecord = param => {
	return request.post('/api/dxt/activateRecord', qs.stringify(param))
};
//迪迦添加限制激活
export const getactivate = param => {
	return request.post('/api/dxt/activate', qs.stringify(param))
};

//迪迦添加文件限制激活
export const getbatchActivate = param => {
	return request.post('/api/dxt/batchActivate', qs.stringify(param))
};


//迪迦激活记录下载
export const downloadActivateRecord = param => {
	return request.post('/api/dxt/downloadActivateRecord', qs.stringify(param), {
		responseType: 'blob'
	})
};


//迪迦停复机下载
export const downloadStopOrOpenRecord = param => {
	return request.post('/api/dxt/downloadStopOrOpenRecord', qs.stringify(param), {
		responseType: 'blob'
	})
};

//迪迦资金池添加金额
export const fundPoolAdd = param => {
	return request.post('/api/dxt/fundPoolAdd', qs.stringify(param))
};

//迪迦资金池记录
export const fundPool = param => {
	return request.post('/api/dxt/fundPool', qs.stringify(param))
};
//迪迦取消停机保号
export const cancelProtection = param => {
	return request.post('/api/dxt/cancelProtection', qs.stringify(param))
};
//迪迦取消停机保号列表
export const cancelProtectionList = param => {
	return request.post('/api/dxt/cancelProtectionList', qs.stringify(param))
};

//迪迦单个充值列表
export const topUpRecord = param => {
	return request.post('/api/dxt/topUpRecord', qs.stringify(param))
};

//迪迦单个充值列表下载
export const topUpRecordDownload = param => {
	return request.post('/api/dxt/topUpRecordDownload', qs.stringify(param))
};
//迪迦添加单个充值
export const AddtopUp = param => {
	return request.post('/api/dxt/topUp', qs.stringify(param))
};
//迪迦批量充值列表
export const batchTopUpRecord = param => {
	return request.post('/api/dxt/batchTopUpRecord', qs.stringify(param))
};
//迪迦APP充值开启
export const stopDxtApp = param => {
	return request.post('/api/topUp/stopDxtApp', qs.stringify(param))
};

//查询是否开启app迪迦充值
export const dxtEnabled = param => {
	return request.post('/api/topUp/dxtEnabled', qs.stringify(param))
};
//迪加复机设置
export const dxtRecordingRecover = param => {
	return request.post('/api/dxt/RecordingRecover', qs.stringify(param))
};

//迪迦话单异常列表
export const dxtErrRecord = param => {
	return request.post('/api/dxt/dxtErrRecord', qs.stringify(param))
};
//迪迦返销
export const ReturnPremium = param => {
	return request.post('/api/dxt/ReturnPremium', qs.stringify(param))
};

//迪迦返销记录
export const ReturnPremiumRecord = param => {
	return request.post('/api/dxt/ReturnPremiumRecord', qs.stringify(param))
};

//迪迦冻结
export const frozen = param => {
	return request.post('/api/dxt/frozen', qs.stringify(param))
};
//迪迦冻结
export const frozenRecord = param => {
	return request.post('/api/dxt/frozenRecord', qs.stringify(param))
};
//迪迦开户信息查询
export const auditResult = param => {
	return request.post('/api/dxt/auditResult  ', qs.stringify(param))
};

//蜗牛添加停复机
export const SnailStopOrOpen = param => {
	return request.post('/api/Snail/stopOrOpen', qs.stringify(param))
};




//蜗牛停复机记录
export const stopOrOpenRecordList = param => {
	return request.post('/api/Snail/stopOrOpenRecord', qs.stringify(param))
};

//蜗牛停复机下载
export const WNdownloadStopOrOpenRecord = param => {
	return request.post('/api/Snail/downloadStopOrOpenRecord', qs.stringify(param), {
		responseType: 'blob'
	})
};

//蜗牛话单异常列表
export const SnailErrRecord = param => {
	return request.post('/api/Snail/ErrRecord', qs.stringify(param))
};

//蜗牛批量加白手动
export const BatchWhitening = param => {
	return request.post('/api/Snail/BatchWhitening', qs.stringify(param))
};


//蜗牛加白记录
export const getContrastInfo = data => {
	return request.get('/api/Snail/getContrastInfo?importFileName=' + data.importFileName +
		'&&pageIndex=' + data.pageIndex + '&&pageSize=' + data.pageSize)

};

//蜗牛充值
export const snailTopUp = param => {
	return request.post('/api/Snail/topUp', qs.stringify(param))
};
//蜗牛充值列表
export const snailShowRemittanceInfo = param => {
	return request.post('/api/Snail/showRemittanceInfo', qs.stringify(param))
};

//蜗牛回退
export const snailBatchDeduction = param => {
	return request.post('/api/Snail/BatchDeduction', qs.stringify(param))
};
//蜗牛回退记录
export const snailTopUpRecord = param => {
	return request.post('/api/Snail/TopUpRecord', qs.stringify(param))
};
//蜗牛限制返档
export const reverseFile = param => {
	return request.post('/api/Snail/ReverseFile', qs.stringify(param))
};
//蜗牛开户进度
export const openAcctSchedule = param => {
	return request.post('/api/Snail/openAcctSchedule', qs.stringify(param))
};
//蜗牛商户余额
export const queryDeptBalance = data => {
	return request.get('/api/Snail/queryDeptBalance')
};

//蜗牛复机设置
export const RecordingRecover = param => {
	return request.post('/api/Snail/RecordingRecover', qs.stringify(param))
};

//迪加停机保号设置
export const updateCancellation = param => {
	return request.post('/api/dxt/updateCancellation', qs.stringify(param))
};
//迪加停机保号查询
export const getCancellation = userId => {
	return request.get('/api/dxt/getCancellation?userId=' + userId)
};
//迪加停复机下载
export const fastdfsfile = param => {
	return request.post('/api/Snail/fastdfsfile', qs.stringify(param), {
		responseType: 'blob'
	})
};
export const fastdfsfile2 = param => {
	return request.post('/api/Snail/fastdfsfile', qs.stringify(param))
};
//蜗牛批量销户
export const fastdfsfile3 = param => {
	return request.post('/api/Snail/fastdfsfile', qs.stringify(param))
};
//蜗牛批量销户记录列表
export const getBatchCancellation = data => {
	return request.get('/api/Snail/getBatchCancellation?importFileName=' + data.importFileName +
		'&&pageIndex=' + data.pageIndex + '&&pageSize=' + data.pageSize + '&&userId=' + data.userId)
};
//蜗牛批量销户
export const batchCancellation = param => {
	return request.post('/api/Snail/batchCancellation', qs.stringify(param))
};


//模版列表查询
export const findCrmTemplate = param => {
	return request.post('/api/customize/findCrmTemplate', qs.stringify(param))
};
//蜗牛APP充值开启
export const stopWNApp = param => {
	return request.post('/api/topUp/stop', qs.stringify(param))
};
//查询是否开启app迪迦充值
export const WNEnabled = param => {
	return request.post('/api/topUp/enabled', qs.stringify(param))
};
//删除crm模板
export const deleteCrmTemplate = param => {
	return request.post('/api/customize/deleteCrmTemplate', qs.stringify(param))
};
//应用crm模板
export const useCrmTemplates = param => {
	return request.post('/api/customize/useCrmTemplates', qs.stringify(param))
};
//添加crm模板
export const addCrmTemplate = param => {
	return request.post('/api/customize/addCrmTemplate', param, {
		headers: {
			'Content-Type': 'application/json'
		}
	})
};